import { render, staticRenderFns } from "./mkp-reset-password-validation.vue?vue&type=template&id=30902b36&scoped=true&"
import script from "./mkp-reset-password-validation.vue?vue&type=script&lang=js&"
export * from "./mkp-reset-password-validation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30902b36",
  null
  
)

export default component.exports