<template>
  <div class=" text-validation-reset">
    <span class="lnr lnr-checkmark-circle"></span>
    <h3 class="validation-subtitle">
      {{ $t("forms.resetPassword.validation.title") }}
    </h3>

    <mkp-text-field>
      {{
        $t("forms.resetPassword.validation.text.first") +
          props.email +
          $t("forms.resetPassword.validation.text.second")
      }}
    </mkp-text-field>

    <mkp-button class="btn-reSend-email" @click="reSendEmail">
      {{ $t("forms.resetPassword.validation.buttons.sendEmail") }}
    </mkp-button>

    <!--    <mkp-button class="" @click="closeModal">
      {{ $t("forms.resetPassword.validation.buttons.end") }}
    </mkp-button>-->
  </div>
</template>

<script>
import MkpTextField from "../../../../simple/text-field/mkp-text-field";
import MkpButton from "../../../../simple/button/mkp-button";
import "./mkp-reset-password-validation.scss";
export default {
  name: "MkpResetPasswordValidation",
  components: { MkpButton, MkpTextField },
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
    reSendEmail() {
      this.$emit("Resetsubmit", this.props);
    },
    closeModal() {
      this.$store.dispatch("setModalState");
    }
  }
};
</script>

<style scoped></style>
